import React from "react";
import styled from "styled-components";

import {
  SanityProductModule,
  SanityProductInfoModule,
  SanityNutritionalInfo,
} from "@graphql-types";
import { Container } from "@util/standard";
import {
  colors,
  colorsRGB,
  DEFAULT_FONT,
  assets,
  MOBILE_BREAKPOINT,
} from "@util/constants";
import { BlocksContent } from "@global";
import Accordion from "react-collapsy";
import { toPlainText } from "@util/helper";

const StyledAccordion = styled(Accordion)`
  border: 0;
  margin-bottom: 0;
  border-bottom: 1px solid ${colors.green};
  &:last-of-type {
    border-bottom: 0;
  }
  .Accordion__header {
    font-family: ${DEFAULT_FONT};
    background: ${colors.transparent};
    font-size: 15px;
    color: ${colors.green};
    padding: 0.4em 0;
    cursor: pointer;
    &:after {
      width: 14px;
      height: 7px;
      right: 18px;
      background: url(${assets.arrowDownDark}) no-repeat center;
      background-size: contain;
    }
  }

  .Accordion__body {
    padding: 0;
    font-size: 15px;
    > div {
      margin-bottom: 1em;
    }
    p {
      font-size: 15px;
      &:first-of-type {
        margin-top: 0.4em;
      }
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    .Accordion__header,
    .Accordion__body,
    .Accordion__body p {
      font-size: 14px;
    }
    .Accordion__header {
      padding: 0.2em 0;
    }
    .Accordion__body {
      > div {
        margin-bottom: 0.6em;
      }
    }
  }
`;

const Table = styled(Container)`
  flex-flow: row wrap;
  margin-top: 1em;
`;

const Tr = styled(Container)`
  width: 100%;
  flex-flow: row nowrap;
  border-bottom: 1px solid ${colorsRGB.merlin(0.3)};
  padding: 11px 0 14px;
  &:last-of-type {
    border-bottom: 0;
  }
`;

const Th = styled(Tr)`
  border-bottom: 1px solid ${colors.green};
  font-weight: 600;
  padding: 0 5px 11px 0;
`;

const Td = styled.pre`
  width: 33.3333%;
  font-family: ${DEFAULT_FONT};
`;

interface Props {
  main: SanityProductModule;
  info: SanityProductInfoModule;
  checkContainerHeight?: () => void;
  isGift?: boolean;
}

const ProductDescription = ({
  main,
  info,
  checkContainerHeight,
  isGift,
}: Props) => {
  const nipInfo = info.nipInfo;
  const firstInfo = nipInfo?.firstInfo;
  const secondInfo = nipInfo?.secondInfo;
  const nipData = [
    ["Energy", "energy"],
    ["Protein", "protein"],
    ["   - Gluten", "gluten"],
    ["Fat, Total", "fat"],
    ["   - Saturated", "saturated"],
    ["Carbohydrate", "carbohydrate"],
    ["   - Sugars", "sugars"],
    ["Dietary Fibre", "dietaryFibre"],
    ["Sodium", "sodium"],
    ["Vitamin C", "vitaminC"],
    ["Polyphenols", "polyphenols"],
  ];

  return (
    <Container flexDirection="column">
      {toPlainText(main.productDirectionsForUse) && (
        <>
          <StyledAccordion
            title={main.productDirectionsForUseTitle ?? "Directions for use"}
            isOpen={false}
            onToggle={checkContainerHeight}
          >
            <Container flexDirection="column">
              <BlocksContent
                blocks={main.productDirectionsForUse?._rawBlocks}
              />
            </Container>
          </StyledAccordion>
        </>
      )}

      {/* Nutrition */}
      {(toPlainText(nipInfo?.nutritionalDescription) ||
        firstInfo ||
        secondInfo) && (
        <StyledAccordion
          title={"Nutritional Information"}
          isOpen={false}
          onToggle={checkContainerHeight}
        >
          <Container flexDirection="column">
            <BlocksContent
              blocks={nipInfo?.nutritionalDescription?._rawBlocks}
            />
            {/* Table */}
            <Table>
              {/* Header */}
              <Th>
                <Td>Avg. Quantity</Td>
                <Td>{nipInfo?.firstTitle}</Td>
                <Td>{nipInfo?.secondTitle}</Td>
              </Th>
              {/* Data */}
              {nipData.map((row, index) => {
                const col1 = firstInfo?.[row[1] as keyof SanityNutritionalInfo];
                const col2 =
                  secondInfo?.[row[1] as keyof SanityNutritionalInfo];
                const isEmpty =
                  !Boolean(col1?.length) && !Boolean(col2?.length);
                if (isEmpty) return;

                return (
                  <Tr key={index}>
                    <Td>{row[0]}</Td>
                    <Td>{col1}</Td>
                    <Td>{col2}</Td>
                  </Tr>
                );
              })}
            </Table>
            {/* <p>{nipInfo?.code}</p> */}
          </Container>
        </StyledAccordion>
      )}

      {/* Ingredients */}
      {toPlainText(info.ingredientsContent) && (
        <StyledAccordion
          title={info.ingredientsTitle}
          isOpen={false}
          onToggle={checkContainerHeight}
        >
          <Container>
            <BlocksContent blocks={info.ingredientsContent?._rawBlocks} />
          </Container>
        </StyledAccordion>
      )}

      {/* More Information */}
      {toPlainText(info.moreInfoContent) && (
        <StyledAccordion
          title={info?.moreInfoTitle}
          isOpen={false}
          onToggle={checkContainerHeight}
        >
          <Container>
            <BlocksContent blocks={info.moreInfoContent?._rawBlocks} />
          </Container>
        </StyledAccordion>
      )}
    </Container>
  );
};

export default ProductDescription;
