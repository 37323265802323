import React, { useRef, useState, useEffect } from "react";
import Sticky from "react-stickynode";
import styled from "styled-components";

import { SanityProduct } from "@graphql-types";
import { Container, H1, P } from "@util/standard";
import {
  colors,
  assets,
  colorsRGB,
  SMALL_LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { BlocksContent, Image, Carousel, Breadcrumb, Button } from "@global";
import AllergenTags from "@shared/sub/allergensInner";
import ProductForm from "@components/products/productForm";
import ProductStock from "@components/products/productStock";
import ProductDescription from "@components/products/productDescription";
import { useStore } from "@state/store";
import { BreadcrumbPageContext, Crumbs } from "@util/types";
import {
  checkProductExistsInBundle,
  useUpdateProductToBundle,
  useCheckScreenWidth,
} from "@util/hooks";
import { CarouselLeftArrow } from "@shared/shared.styles";
import { handleSaveRecipeProduct } from "@util/getFirebase";

const StyledSticky = styled(Sticky)`
  width: 50%;
  .breadcrumb {
    position: absolute;
    z-index: 2;
    top: 100px;
    left: 0;
    a {
      color: ${colorsRGB.merlin(0.7)};
      &:hover {
        color: ${colorsRGB.merlin(1)};
      }
    }
  }
  .slick-slider {
    height: 100%;
  }
  .slick-list {
    height: 100%;
  }
  .slick-track {
    display: flex;
    height: 100%;
  }
  .slick-slide {
    height: inherit;
    > div {
      height: 100%;
    }
  }
  .slick-dots {
    bottom: 35px;
    text-align: left;
    padding: 0 25px;
    li {
      width: 9px;
      height: 9px;
      button {
        width: 9px;
        height: 9px;
        &:before {
          content: "";
          background: ${colors.white};
          border-radius: 100px;
          width: 9px;
          opacity: 0.7;
          height: 9px;
        }
      }
      &.slick-active {
        button {
          &:before {
            background: ${colors.green};
            opacity: 1;
          }
        }
      }
    }
  }
  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    .breadcrumb {
      top: 100px;
    }
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    .breadcrumb {
      top: 73px;
    }
  }
`;

const HeartIcon = styled.div`
  position: absolute;
  top: 130px;
  right: 30px;
  z-index: 21;
  background-color: ${colors.green};
  width: 38px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    top: 110px;
  }
`;

interface Props {
  product: SanityProduct;
  isGift: boolean;
  parentCrumb: Crumbs;
  pageContext: BreadcrumbPageContext;
  bundleId?: string | undefined | null;
  categoryColour?: string;
}

const ProductInfo = ({
  product,
  isGift,
  parentCrumb,
  pageContext,
  bundleId,
  categoryColour,
}: Props) => {
  const main = product?.content?.main;
  const info = product?.content?.info;
  const shopify = product?.content?.shopify;
  if (!main) return null;
  if (!info) return null;
  if (!shopify) return null;

  const NextIcon = (
    <CarouselLeftArrow position="absolute" right="5px" hideOnMobile>
      <img src={assets.arrowRight} alt="right-arrow" />
    </CarouselLeftArrow>
  );

  const PrevIcon = (
    <CarouselLeftArrow rightArrow position="absolute" left="5px" hideOnMobile>
      <img src={assets.arrowRight} alt="left-arrow" />
    </CarouselLeftArrow>
  );

  const { savedProducts } = useStore();
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();
  const elContainer = useRef<any>(null);

  const [boundaryHeight, setBoundaryHeight] = useState<number>(
    elContainer?.current?.offsetHeight
  );
  const checkContainerHeight = () => {
    setBoundaryHeight(elContainer?.current?.offsetHeight);
    window.scrollTo(window.scrollX, window.scrollY - 1);
  };
  useEffect(() => {
    setBoundaryHeight(elContainer?.current?.offsetHeight);
  }, []);

  const productIsInBundle = checkProductExistsInBundle(bundleId, product?.id);
  const handleAddToBundle = useUpdateProductToBundle(
    bundleId,
    {
      id: product?.id,
      slug: product?.content?.main?.slug?.current,
      title: product?.content?.main?.title,
      lineId: Math.random().toString(36).substring(7),
      prodImage: product?.content?.main?.mainImage,
    },
    productIsInBundle?.lineId
  );

  return (
    <Container
      width="100%"
      isMobileColumn
      ref={elContainer}
      overflow="hidden !important"
    >
      <StyledSticky
        enabled={isTabletWidth || isMobileWidth ? false : true}
        bottomBoundary={boundaryHeight}
      >
        <Container
          height={isTabletWidth ? "80vh" : isMobileWidth ? "500px" : "100vh"}
          width="100%"
          flexDirection="column"
          backgroundRGBColor={categoryColour ?? colors.sandstone}
        >
          <Breadcrumb
            parentCrumb={parentCrumb ?? undefined}
            pageContext={pageContext}
            crumbLabel={main.title ?? undefined}
          />

          <Carousel
            iconButton
            prevIcon={PrevIcon}
            nextIcon={NextIcon}
            hideOnMobile
          >
            <Container
              display="flex !important"
              height="100%"
              justifyContent="center"
              alignItems="center"
              mobilePadding="70px 0 0"
            >
              {isGift ? (
                <Container
                  width="100%"
                  height="100%"
                  margin="auto"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    width="100%"
                    height="100%"
                    imageData={main.mainImage?.asset?.gatsbyImageData}
                    mobileImage={
                      main?.mainImage?.mobileImage?.asset?.gatsbyImageData
                    }
                    objectFit="contain"
                    margin="auto"
                  />
                </Container>
              ) : (
                <Container
                  width="80%"
                  height={
                    isTabletWidth
                      ? "calc(65vh - 100px)"
                      : isMobileWidth
                      ? "320px"
                      : "calc(90vh - 180px)"
                  }
                  margin={
                    isTabletWidth
                      ? "100px auto auto"
                      : isMobileWidth
                      ? "80px auto auto"
                      : "180px auto auto"
                  }
                  justifyContent="center"
                >
                  <Image
                    width="100%"
                    height="100%"
                    imageData={main.mainImage?.asset?.gatsbyImageData}
                    mobileImage={
                      main?.mainImage?.mobileImage?.asset?.gatsbyImageData
                    }
                    objectFit="contain"
                    margin="auto"
                  />
                </Container>
              )}
            </Container>
            {main.otherImages?.map(image => {
              if (!image) return;
              return (
                <Container
                  width="80%"
                  height={
                    isTabletWidth
                      ? "calc(65vh - 100px)"
                      : isMobileWidth
                      ? "320px"
                      : "calc(90vh - 180px)"
                  }
                  margin={
                    isTabletWidth
                      ? "100px auto auto"
                      : isMobileWidth
                      ? "80px auto auto"
                      : "180px auto auto"
                  }
                  justifyContent="center"
                >
                  <Image
                    width="100%"
                    height="100%"
                    key={image._key}
                    imageData={image.asset?.gatsbyImageData}
                    mobileImage={image?.mobileImage?.asset?.gatsbyImageData}
                    // @ts-ignore
                    objectFit={image.objectFit || "contain"}
                  />
                </Container>
              );
            })}
          </Carousel>
        </Container>
      </StyledSticky>
      <Container
        width="50%"
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="center"
        backgroundColor="white"
        tabletWidth="100%"
        margin="0 0 35px"
      >
        <HeartIcon>
          <img
            src={
              savedProducts?.includes(product.id!)
                ? assets.favouritedIcon
                : assets.favouriteIcon
            }
            alt="icon-button"
            width="17px"
            height="17px"
            onClick={() => handleSaveRecipeProduct(product.id, "product")}
          />
        </HeartIcon>
        <Container
          width={
            isTabletWidth
              ? "500px"
              : isMobileWidth
              ? "calc(100% - 100px)"
              : "60%"
          }
          display="flex"
          margin="calc(130px + 5vh) 0 0"
          tabletMargin="52px 0 0"
          flexDirection="column"
        >
          {main.title && (
            <H1
              fontSize={isTabletWidth || isMobileWidth ? 25 : 38}
              lineHeight="120%"
            >
              {main.title}
            </H1>
          )}
          <Container justifyContent="flex-start">
            <P margin="0 0 10px">{info.declaredWeight}</P>
          </Container>
          <AllergenTags allergens={info.allergens} />
          <BlocksContent blocks={main.productDescription?._rawBlocks} />
          {bundleId != undefined ? (
            <Button
              text={
                productIsInBundle?.lineId
                  ? "REMOVE FROM BUNDLE"
                  : "ADD TO BUNDLE"
              }
              width="100%"
              theme="base"
              onClick={handleAddToBundle}
              margin="0 0 15px 0"
            />
          ) : (
            <ProductForm shopifyData={shopify} />
          )}
          {((info.stockists && info.stockists.length > 0) ||
            info.deliveryInfo) && (
            <ProductStock
              stockists={info.stockists}
              delivery={info.deliveryInfo}
            />
          )}
          <ProductDescription
            main={main}
            info={info}
            checkContainerHeight={checkContainerHeight}
            isGift={isGift}
          />
        </Container>
      </Container>
    </Container>
  );
};

export default ProductInfo;
